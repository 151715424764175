import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

// The till marketplace is currently closed while your local farmers harvest food for next week. You'll be able to purchase our fresh food again on Sunday!
export default () => {
    const [clientLoaded, setClientLoaded] = React.useState(false);
    React.useEffect(() => {
        setClientLoaded(true);
    }, []);
    const { marketStatus: { marketStatusMessage }} = useStaticQuery(graphql`
        query {
            marketStatus {
                marketStatusMessage
            }
        }
    `)
    if (clientLoaded) {
        return (
            <div className='yellow-alert-msg left-align-mobile'>
                <h6>{marketStatusMessage} {' '}<Link to="/how-till-works/" style={{color: 'inherit', textDecoration: 'underline', fontSize: 'inherit'}}>Learn more about how till works</Link>.</h6>
            </div>
        )
    }
    return null;
}