import React from 'react';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FOOD_ROUTE } from '../constants';
import ProductPlaceholderImage from './ProductPlaceholderImage';

export default ({ products, handleProductClick }) => {
  return (
    <div className='grid-container add-width-1'>
      {products.map(({ node }, index) => {
        const { path, productImage, displayPrice, name } = node;
        const content = (
          <>
            <div className='farm-img-container'>
              {productImage ? <GatsbyImage fluid={{ ...productImage.childImageSharp.fluid, aspectRatio: 1 }} /> : <ProductPlaceholderImage />}
            </div>
            <p className='name'><strong>{name}</strong></p>
            <p className='text-muted'>{displayPrice}</p>
          </>
        )
        return (
          <div className='grid-item-farm-product' key={`product-${index}`}>
            {handleProductClick ? <button onClick={() => handleProductClick(node)} className="product-btn">{content}</button> : <Link to={`${FOOD_ROUTE}${path}`}>{content}</Link>}
          </div>
        )
      })}
    </div>
  )
}