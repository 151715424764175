import React from 'react';

export default ({ soldOut, handleAddBtnClick, btnClasses, status, marketOpen }) => {
    if (typeof window === "undefined") {
        return null;
    }
    else if (!marketOpen) {
        return <div className={btnClasses + ' disabled'}>MARKET CLOSED</div>
    }
    else if (status === "INACTIVE") {
        return <div className={btnClasses + ' disabled'}>NOT AVAILABLE</div>
    }
    else if (soldOut) {
        return <div className={btnClasses + ' disabled'}>SOLD OUT</div>
    }
    return <button className={btnClasses} onClick={handleAddBtnClick}>ADD TO CART</button>
}