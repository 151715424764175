import React from 'react';

export default ({cartQty, soldOut}) => {
    if (typeof window === "undefined") {
        return null;
    }
    else if (soldOut) {
        return (
            <div className='product-overlay'>
                <div>SOLD OUT</div>
            </div>
        )
    }
    else if (cartQty > 0) {
        return (
            <div className='product-overlay'>
                <div className='count'>{cartQty}</div>
                <div>IN YOUR CART</div>
            </div>
        )
    }
    return null
}