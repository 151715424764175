import React from 'react';
import axios from 'axios';
import ProductContainer from '../components/ProductContainer';
import FarmProductList from '../components/FarmProductList';
import ProductPageContainer from '../components/ProductPageContainer';
import { getCartItemInfo, makeCancellable } from '../utils';
import MarketClosedWarning from './MarketClosedWarning';
import { BASE_PATH } from '../config';

export default ({ addToCart, cartItems, initProduct, sameFarmProducts, addSnackbarMessage, refreshProductData, handleProductClick, marketOpen }) => {
    const [product, setProduct] = React.useState(initProduct);
    const cartItemInfo = getCartItemInfo(cartItems);
    React.useEffect(() => {
        // refreshProductData will only exist if the user is on the actual product page; it won't exist if the user is viewing through product dialog
        if (refreshProductData) {
            const wrappedFunction = makeCancellable(axios.get(`${BASE_PATH}/getSingleProduct?productID=${product.id}`));
            wrappedFunction.promise
            .then(({ data }) => {
                setProduct({ ...product, ...data.product })
            })
            .catch(err => console.log(err))
            return () => wrappedFunction.cancel()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            {marketOpen === false && <MarketClosedWarning />}
            <div className='layout-container'>
                <div className='product-page-container'>
                    <ProductContainer 
                        BodyComponent={ProductPageContainer}
                        product={product}
                        addToCart={addToCart}
                        cartItem={cartItemInfo[product.id]}
                        addSnackbarMessage={addSnackbarMessage}
                        marketOpen={marketOpen}
                    />
                    {sameFarmProducts.length > 0 && 
                        <div className='more-products'>
                            <h5>More products from this farm</h5>
                            <FarmProductList products={sameFarmProducts} handleProductClick={handleProductClick} />
                        </div>
                    }
                </div>
            </div>
        </>
    )
}