import React from 'react';
import GatsbyImage from 'gatsby-image';
import Dialog from '../components/Dialog';
import Icon from '../components/Icon';
import { toCurrency } from '../utils';
import ProductPlaceholderImage from './ProductPlaceholderImage';

function sortFunction(a, b) {
    if (a.price === b.price) {
        if (a.name > b.name) {
            return 1;
        }
        return -1;
    }
    else if (a.price > b.price) {
        return 1;
    }
    return -1;
}

export default ({ cartItem, product, addToCart, BodyComponent, addSnackbarMessage, setActiveProduct, marketOpen }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    function handleClose() {
        setDialogOpen(false);
        setTimeout(() => {
            setSelectedItem(null);
        }, 250)
    }
    function handleAddBtnClick() {
        setDialogOpen(true);
    }
    function handleAddToCart(item) {
        const cartIndex = cartItem && cartItem.hasOwnProperty([item.name]) ? cartItem[item.name] : null;
        const itemDetails = {
            name: `${product.name} - ${item.name}`,
            priceOptionItemName: item.name,
            farmName: product.farm.name,
            productImage: product.productImage,
            price: item.price,
            itemID: product.id,
            qtyAvailable: item.qtyAvailable
        }
        addToCart(cartIndex, itemDetails);
        setDialogOpen(false);
    }
    React.useEffect(() => {
        if (selectedItem) {
            handleAddToCart(selectedItem);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem])
    
    const soldOut = product.priceOptionItems.filter(({qtyAvailable}) => qtyAvailable > 0).length === 0;
    const handleExited = () => {
        setSelectedItem(null);
    }
    const sortedPriceOptionItems = product.priceOptionItems.sort(sortFunction);
    return (
        <>
            <BodyComponent 
                cartQty={cartItem ? cartItem.qty : 0}
                handleAddBtnClick={handleAddBtnClick}
                product={product}
                soldOut={soldOut}
                addSnackbarMessage={addSnackbarMessage}
                setActiveProduct={setActiveProduct}
                marketOpen={marketOpen}
            />
            <Dialog onClose={handleClose} open={dialogOpen} maxWidth={650} fullWidth onExited={handleExited}>
                <div className='form-dialog add-padding'>
                    <button className='btn close-btn' onClick={handleClose}>
                        <Icon iconName='times' width={18} />
                    </button>
                    <div className='content'>
                        <div className='grid-container'>
                            <div className='grid-item-3 add-padding'>
                                <div className='img-container'>
                                    {product.productImage ? <GatsbyImage fluid={{ ...product.productImage.childImageSharp.fluid, aspectRatio: 1 }} /> : <ProductPlaceholderImage />}
                                </div>
                            </div>
                            <div className='grid-item-2-3 add-padding'>
                                <h5 className='text-primary'>PLEASE SELECT AN OPTION:</h5>
                                <h6>{product.name} - {product.farm.name}</h6>
                                <ul className='option-list'>
                                    {sortedPriceOptionItems.map(item => {
                                        if (item.qtyAvailable > 0) {
                                            const handleClick = () => {
                                                setSelectedItem({ name: item.name, price: item.price, qtyAvailable: item.qtyAvailable })
                                            }
                                            return (
                                                <li key={item.name} className={selectedItem && selectedItem.name === item.name ? 'selected' : ''}>
                                                    <button className='btn' onClick={handleClick}>
                                                        <div className='icon-item'>
                                                            <div className='icon-container'>
                                                                <Icon iconName='check' width={20}/>
                                                            </div>
                                                        </div>
                                                        <div className='option-item'>
                                                            <p><strong>{item.name}{' - $'}{toCurrency(item.price)}</strong></p>
                                                            <p className='text-muted'>{item.description}</p>
                                                        </div>
                                                    </button>
                                                </li>
                                            )
                                        }
                                        return null
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}