import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ProductPageWrapper from '../components/ProductPageWrapper';
import { FOOD_ROUTE } from '../constants';

const ProductPage = ({ data: { marketStatus: { marketOpen }, product, allProduct: { edges: sameFarmProducts } }}) => {
    const pageTitle = `${product.farm.name} - ${product.name}`;
    return (
        <Layout pageTitle={pageTitle} addLayoutProps ogUrl={`${FOOD_ROUTE}${product.path}/`}>
            <ProductPageWrapper 
                initProduct={product} 
                sameFarmProducts={sameFarmProducts} 
                refreshProductData 
                marketOpen={marketOpen} 
            />
        </Layout>
    )
}

export default ProductPage;

export const query = graphql`
  query($id: String!, $farmID: String!) {
    product(id: {eq: $id}) {
        id
        about
        name
        path
        price
        displayPrice
        priceOption
        qtyAvailable
        priceOptionItems {
            description
            name
            price
            qtyAvailable
        }
        farm {
            path
            name
        }
        productImage {
            childImageSharp {
                fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
                }
            }
        }
    }
    allProduct(filter: {status: {eq: "ACTIVE"}, farmID: {eq: $farmID}, id: {ne: $id}}) {
        edges {
            node {
                displayPrice
                about
                name
                path
                productImage {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    } 
                }
            }
        }
    }
    marketStatus {
        marketOpen
    }
  }
`