import React from 'react';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import ProductOverlay from './ProductOverlay';
import ProductPlaceholderImage from './ProductPlaceholderImage';
import AddToCartButton from './AddToCartButton';
import { FARM_ROUTE, FOOD_ROUTE } from '../constants';

export default ({ handleAddBtnClick, product, cartQty, soldOut, setActiveProduct, marketOpen }) => {
    const ClickableComponent = ({ children, className }) => {
        if (setActiveProduct) {
            function handleClick() {
                setActiveProduct(product)
            }
            return <button className={className} onClick={handleClick}>{children}</button>
        }
        return <Link to={`${FOOD_ROUTE}${product.path}`} className={className}>{children}</Link>
    }
    return (
        <div className='paper product-container'>
            <div>
                <ClickableComponent className="img-container">
                    {product.productImage ? <GatsbyImage fluid={{ ...product.productImage.childImageSharp.fluid, aspectRatio: 1 }} /> : <ProductPlaceholderImage />}
                    <ProductOverlay cartQty={cartQty} soldOut={soldOut} />
                </ClickableComponent>
                <div className='body'>
                    <div>
                        <ClickableComponent className='name'>{product.name}</ClickableComponent>
                        <p><Link to={`${FARM_ROUTE}${product.farm.path}`} className='product-farm-link'>{product.farm.name}</Link></p>
                    </div>
                </div>
            </div>
            <div className='add-padding'>
                <p className='price'>{product.displayPrice}</p>
                <AddToCartButton
                    marketOpen={marketOpen}
                    soldOut={soldOut} 
                    handleAddBtnClick={handleAddBtnClick} 
                    btnClasses='btn primary-btn small-btn full-width'
                    status={product.status}
                />
            </div>
        </div>
    )
}