import React from "react";

const ProductPlaceholderImage = () => {
  return (
    <svg viewBox="0 0 340 340" width="100%">
      <path d="M0 0L340 0L340 340L0 340L0 0Z" fill="#eeeeee" />
      <path
        d="M29.29 66.69L91.96 135.43L99.28 127.68L34.81 60.86L40.17 55.24L105.05 122.46L111.12 116.06L46.24 48.82L52.15 42.6L117.03 109.83L124.23 102.23L57.27 37.15L62.84 31.28C99.84 61.13 120.4 77.71 124.51 81.02C124.51 81.02 126.74 82.99 127.52 83.79C135.98 92.56 139.66 104.51 138.51 116.02C141.3 160.81 178.27 170.52 183.79 176.24C189.9 182.6 284.16 280.27 284.16 280.27C284.16 280.27 284.15 280.28 284.15 280.28C284.22 280.37 284.3 280.39 284.38 280.47C290.16 286.45 290.19 296.21 284.47 302.24C278.77 308.28 269.41 308.32 263.66 302.35C263.58 302.26 263.54 302.17 263.48 302.11C263.48 302.11 263.47 302.12 263.47 302.12C263.47 302.12 170.36 205.68 163.26 198.31C156.85 191.65 141.64 151.69 102.41 151.45C102.37 151.43 102.27 151.42 102.21 151.42C92.98 151.73 83.66 148.57 76.21 141.83C75.68 141.35 74.47 140.05 74.47 140.05C71.06 135.59 53.97 113.29 23.2 73.14L29.29 66.69Z"
        fill="#777777"
      />
      <path
        d="M304.26 41.7C284.62 23.69 250.52 31.1 218.02 66.53C189.72 97.38 194.61 124.7 206.8 143.72C177.37 170.25 128.54 211.52 101.05 241.49C53.36 293.47 58.59 298.48 65.18 304.52C71.76 310.56 77.2 315.34 124.89 263.36C152.38 233.39 195.92 183.05 219.82 151.44C239.82 161.95 260.83 162.62 289.14 131.76C321.63 96.34 323.9 59.72 304.26 41.7Z"
        fill="#777777"
      />
    </svg>
  );
};

export default ProductPlaceholderImage;
