import React from 'react';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import ProductOverlay from './ProductOverlay';
import FavoriteButton from './FavoriteButton';
import AddToCartButton from './AddToCartButton';
import { FOOD_ROUTE, FARM_ROUTE } from '../constants';
import ProductPlaceholderImage from './ProductPlaceholderImage';

export default ({ handleAddBtnClick, product, cartQty, soldOut, addSnackbarMessage, marketOpen }) => {
    return (
        <div className='grid-container add-width-2'>
            <div className='grid-item-2 add-padding-2'>
                <div className='img-container'>
                    {product.productImage ? <GatsbyImage fluid={{ ...product.productImage.childImageSharp.fluid, aspectRatio: 1 }} /> : <ProductPlaceholderImage />}
                    <ProductOverlay cartQty={cartQty} soldOut={soldOut} />
                </div>
            </div>
            <div className='grid-item-2 add-padding-2'>
                <h3>{product.name}</h3>
                {product.farm && <p><Link to={`${FARM_ROUTE}${product.farm.path}`}>{product.farm.name}</Link></p>}
                <div dangerouslySetInnerHTML={{__html: product.about}} />
                <div className="margin-top-4">
                    <h4>{product.displayPrice}</h4>
                    <div className='grid-container add-width-1 btn-container'>
                        <div className='add-padding'>
                            <AddToCartButton 
                                soldOut={soldOut} 
                                handleAddBtnClick={handleAddBtnClick} 
                                btnClasses='btn primary-btn full-width' 
                                status={product.status}
                                marketOpen={marketOpen}
                            />
                        </div>
                        <div className='add-padding'>
                            <FavoriteButton 
                                itemID={product.id} 
                                favoriteType="PRODUCTS" 
                                addSnackbarMessage={addSnackbarMessage}
                                redirectPath={`${FOOD_ROUTE}${product.path}`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}