import React from 'react';
import MultiProductContainer from './MultiProductContainer';
import ProductContainerBody from './ProductContainerBody';

export default ({ addToCart, cartItem, product, BodyComponent=ProductContainerBody, addSnackbarMessage, setActiveProduct, marketOpen }) => {
    const sharedProps = {
        marketOpen,
        setActiveProduct,
        addSnackbarMessage,
        product
    }
    if (product.priceOption === "MULTIPLE") {
        return (
            <MultiProductContainer
                cartItem={cartItem}
                addToCart={addToCart}
                BodyComponent={BodyComponent}
                {...sharedProps}
            />
        )
    }
    const itemDetails = {
        name: product.name,
        farmName: product.farm.name,
        productImage: product.productImage,
        price: product.price,
        itemID: product.id,
        qtyAvailable: product.qtyAvailable
    }
    const cartIndex = cartItem ? cartItem.index : null;
    const handleAddBtnClick = () => {
        addToCart(cartIndex, itemDetails);
    }
    return (
        <BodyComponent
            cartQty={cartItem ? cartItem.qty : 0}
            handleAddBtnClick={handleAddBtnClick}
            soldOut={product.qtyAvailable === 0}
            {...sharedProps}
        />
    )
}