import React from 'react';
import { Link } from 'gatsby';
import Icon from './Icon';
import { AuthContext } from './Layout';
import Dialog from './Dialog';
import { getLocalStorage, FAVORITE_PRODUCTS, FAVORITE_FARMS, setLocalStorage, AUTH_USER, postWithToken } from '../utils';
import { BASE_PATH } from '../config';

const LoginDialog = ({ dialogOpen, setDialogOpen, redirectPath }) => {
    function handleClose() {
        setDialogOpen(false);
    }
    return (
        <Dialog open={dialogOpen} onClose={handleClose} maxWidth={450}>
            <div className='add-padding-2 position-relative'>
                <button className='btn close-btn' onClick={handleClose}>
                    <Icon iconName='times' width={18} />
                </button>
                <h5 className="margin-bottom-2">Log in to add favorites</h5>
                <p className="margin-bottom-2">We're so glad you like this as much as we do. Would you mind logging in so we can save it for you?</p>
                <div className='flex-btn-container'>
                    <div>
                        <button className='btn white-btn full-width small-btn' onClick={handleClose}>CANCEL</button>
                    </div>
                    <div>
                        <Link className='btn primary-btn full-width small-btn' to="/login/" state={{ redirectTo: redirectPath }}>LOG IN</Link>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ({ favoriteType, itemID, addSnackbarMessage, redirectPath }) => {
    const authUser = getLocalStorage(AUTH_USER);
    const [favoriteProducts, setFavoriteProducts] = React.useState(getLocalStorage(FAVORITE_PRODUCTS));
    const [favoriteFarms, setFavoriteFarms] = React.useState(getLocalStorage(FAVORITE_FARMS));
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const user = React.useContext(AuthContext);

    let updateFunction;
    let storageName;
    let itemList;
    if (favoriteType === "PRODUCTS") {
        itemList = favoriteProducts;
        updateFunction = setFavoriteProducts;
        storageName = FAVORITE_PRODUCTS;
    }
    else if (favoriteType === "FARMS") {
        itemList = favoriteFarms;
        updateFunction = setFavoriteFarms;
        storageName = FAVORITE_FARMS;
    }
    if (!itemList) {
        itemList = [];
    }
    const itemIndex = itemList.indexOf(itemID);
    function handleAdd() {
        if (!authUser) {
            setDialogOpen(true);
        }
        else {
            const newItems = [...itemList, itemID];
            setLocalStorage(storageName, newItems);
            addSnackbarMessage("Added to favorites");
            updateFunction(newItems);
            postWithToken(user.getIdToken(), `${BASE_PATH}/updateUserFavorites`, { itemID, favoriteType, updateType: "ADD" });
        }
    }
    function handleRemove() {
        const newItems = itemList.filter((item, index) => index !== itemIndex);
        setLocalStorage(storageName, newItems);
        addSnackbarMessage("Removed from favorites");
        updateFunction(newItems);
        postWithToken(user.getIdToken(), `${BASE_PATH}/updateUserFavorites`, { itemID, favoriteType, updateType: "REMOVE" });
    }
    if (typeof window === "undefined") {
        return null;
    }
    const btnDisabled = authUser && !user; // Only disable the button if the user is actually logged in but firebase isn't available yet
    return (
        <div>
            {!authUser && <LoginDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} redirectPath={redirectPath} />}
            {!authUser || itemIndex === -1 ? 
                <button className={`btn favorite-btn secondary-outline-btn ${btnDisabled ? ' disabled' : ''}`} disabled={btnDisabled} onClick={handleAdd}>
                    <span><Icon iconName='starOutline' width={22} /></span>
                    <span>ADD TO FAVORITES</span>
                </button>
                :
                <button className={`btn favorite-btn secondary-btn ${btnDisabled ? ' disabled' : ''}`} disabled={btnDisabled} onClick={handleRemove}>
                    <span><Icon iconName='star' width={22} /></span>
                    <span>FAVORITED</span>
                </button>
            }
        </div>
    )
}